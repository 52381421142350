<template>
  <header>
    <nav class="navbar is-fixed-top">
      <div class="navbar-brand">
        <a class="navbar-item logo" href="/">
          <img class="logo-header" src="@/assets/logo.png" alt="Intelligential">
        </a>

       <a role="button" @click="showMenu" class="navbar-burger burger none"  data-target="navMenubd-intelligential" aria-label="menu" aria-expanded="false">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navMenubd-intelligential" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item pushpin">
            <!-- <img src="@/assets/pushpin.png"> -->
          </a>
        </div>

        <div class="navbar-end">
          <div class="navbar-item has-dropdown is-hoverable is-mega">
            <div class="navbar-link">
              Menú
            </div>
            <div id="blogDropdown" class="navbar-dropdown " data-style="width: 18rem;">
              <div class="container is-fluid">
                <div class="columns">
                  <div class="column is-3">
                    <a class="navbar-item child option" :href="getLinkLocal({})">
                      <label>Dashboard</label>
                      <hr class="separator"/>
                    </a>
                  </div>
                </div>
                <div class="columns">
               </div>

               <div class="columns is-multiline" v-for="(category, index) in menu.categories" :key="index">
                 <div class="column is-12 contenedor-line">
                   <h1 class="title is-6 is-mega-menu-title principal">{{category.name ? category.name.toUpperCase() : ''}}</h1>
                   <div class="columns">
                     <div class="column" v-for="(subcategory, index) in category.subcategories" :key="index">
                       <h1 class="title is-6 is-mega-menu-title" v-if="subcategory.name">{{subcategory.name}}</h1>
                       <template v-for="(item, index) in subcategory.items">
                        <a
                          :key="index"
                          class="navbar-item child option"
                          :target="item.section === 'external' ? '_blank' : ''"
                          :href="getLinkLocal({ section: item.section, path: item.path })"
                        >
                          <label>{{item.name}}</label>
                          <hr class="separator"/>
                        </a>
                       </template>
                     </div>
                   </div>
                 </div>
               </div>
              </div>

              <hr class="navbar-divider">
              <div class="navbar-item">
                <div class="navbar-content">
                  <div class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item">
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                       <strong>Smart Native</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="navbar-item is-hoverable">
            <span class="icon is-large">
              <i class="fas fa-bell material-icons"></i>
              <span v-if="numNotifications > 0" class="badge notific-count">{{numNotifications}}</span>
            </span>
            <div class="navbar-dropdown list-notifications">
              <template v-if="!this.notifications || this.notifications.length === 0">
                <label class="navbar-item child option">Sin notificaciones.</label>
                <hr class="navbar-divider">
              </template>
              <template v-else v-for="(notification, index) in this.notifications">
                <div :key="index" @click="markNotification(notification.index)" :class="[notification.opened ? 'messageReaded' : 'messageUnreaded']">
                <a
                  :key="index"
                  class="navbar-item child option"
                  :href="getLinkNotification(notification)"
                >
                  <label class="title">{{notification.title}}</label>
                  <label :key="index" v-for="(message, index) in getNotificationText(notification.message)" class="currentMessage message-text">{{message}}</label>
                  <p class="currentMessage date">{{formatDate(notification.createdAt, 'DD-MMMM-YYYY', systemConfiguration.lang)}}</p>
                </a>
                </div>
                <hr :key="'s'+index" class="navbar-divider">
              </template>
            </div>
          </div>
          <div class="navbar-border-radius">
            <div class="navbar-it">
              {{ financialCompany.name }}
            </div>
            <div class="vertical-separator" />
            <div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link detail-user is-active">
                <div class="detail-user-text">
                  <label>
                    <span class="icon has-text-white">
                      <i class="fas fa-user material-icons"></i>
                    </span>
                    {{user.fullName  || ''}}
                  </label>
                  <label class="is-pulled-right">
                    <b> {{role.code ? getRoleName(role) : ''}} </b>
                  </label>
                </div>
                <div class="navbar-dropdown">
                  <a class="navbar-item " :href="getLinkLocal({ section: 'general', path: 'my-profile' })">
                    <p>
                      Mi perfil
                    </p>
                  </a>
                  <hr class="navbar-divider">
                  <a class="navbar-item " :href="getLinkLocal({ section: 'auth', path: 'change-password' })">
                    <p>
                      Cambiar contraseña
                    </p>
                  </a>
                  <hr class="navbar-divider">
                  <label class="url-link navbar-item" @click="logout()">
                    <p>
                      Cerrar sesión
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import mixins from '../mixins/formats'

export default {
  name: 'TheHeader',
  mixins: [mixins],
  props: {
    disableMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      user: {},
      role: {},
      financialCompany: {},
      menu: {},
      notifications: [],
      newNotifications: [],
      numNotifications: 0
    }
  },
  computed: {
    ...mapState({
      authentication: state => state.authentication,
      systemConfiguration: state => state.configuration
    })
  },
  methods: {
    ...mapActions(['logout', 'getLink', 'getMenu', 'profileMe', 'getUserNotifications', 'updateNotification']),
    ...mapMutations(['saveCompanyProfile']),
    showMenu () {
      const burger = document.querySelector('.burger')
      const nav = document.querySelector('#' + burger.dataset.target)
      burger.classList.toggle('is-active')
      nav.classList.toggle('is-active')
    },
    getLinkLocal ({ section, path }) {
      if (section === 'external') {
        return path
      }
      const baseURL = `${process.env.VUE_APP_PROTOCOL}://${window.location.hostname}`
      const linkPath = path ? `${process.env[`VUE_APP_${section.toUpperCase()}`]}/${path}`.replace('//', '/') : ''
      return `${baseURL}${linkPath}`
    },
    getLinkNotification (notification) {
      const linkArr = notification.link ? notification.link.split(':') : []
      const section = linkArr[1]
      notification.linkType = linkArr[0]
      linkArr.splice(0, 2)
      const link = linkArr.join('/')
      return this.getLinkLocal({ section, path: link })
    },
    getNotificationText (notification) {
      let control = 0
      const message = []
      let textArr = []
      let isMoreActive = true

      notification.split(' ').forEach(str => {
        control += str.length
        if (control > 38) {
          if (message.length >= 1) {
            if (isMoreActive) message.push(textArr.join(' ') + '...')
            isMoreActive = false
          } else {
            message.push(textArr.join(' '))
          }
          control = 0
          textArr = []
        }
        textArr.push(str)
      })
      if (textArr.length > 0 && isMoreActive) message.push(textArr.join(' '))
      return message
    },
    async markNotification (notificationIndex) {
      await this.updateNotification(notificationIndex)
    }
  },
  mounted () {
    this.getMenu().then(resp => {
      this.menu = resp
    })
    this.profileMe().then(resp => {
      const { financialCompany, role, ...user } = resp
      this.user = user
      this.role = role
      this.financialCompany = financialCompany
      this.saveCompanyProfile(resp)
    })
    this.getUserNotifications().then(resp => {
      this.notifications = resp.notifications
      this.newNotifications = resp.notifications.filter(notification => !notification.opened)
      this.numNotifications = this.newNotifications.length
    })
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/v3-alpha/header.scss';
</style>

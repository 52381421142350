import moment from 'moment-timezone'

export default {
  methods: {
    formatDate (date, format = 'DD-MM-YY HH:mm:ss', lang = 'es') {
      return moment(new Date(parseInt(date))).lang(lang).format(format)
    },
    formatMoney (amount) {
      return parseInt(amount).toLocaleString('en', { style: 'currency', currency: 'USD' })
    },
    getRoleName (role) {
      const roleName = role.code === 'custom' && role.name
        ? role.name
        : this.$t(`roles.${role.code}`, this.systemConfiguration.lang)
      return roleName
    }
  }
}
